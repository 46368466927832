// Taken from (for Sentry error support): https://github.com/vercel/next.js/blob/d2caaeab6491f1be80b3779b83e2902fc73223f9/examples/with-sentry/pages/_app.js
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { SWRConfig } from 'swr';
import '@stripe/stripe-js'; // This is needed to run the stripe fraud tracking on every page
import { load, trackPageview } from 'fathom-client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { swrConfig } from 'lib/fetchJson';
import { posthogConfigPagesRouter } from 'lib/posthog';
import useUser from 'lib/useUser';

// https://nextjs.org/docs/basic-features/built-in-css-support#adding-a-global-stylesheet
import '../styles/globals.css';
import './layout.scss';

const FATHOM_SITE_ID =
  process.env.FATHOM_SITE_ID || process.env.NEXT_PUBLIC_FATHOM_SITE_ID;

// Record a pageview when route changes
Router.events.on('routeChangeComplete', (as, routeProps) => {
  if (!routeProps.shallow) {
    trackPageview();
  }
});

const App = ({ Component, pageProps }) => {
  const { user } = useUser();

  const oldUrlRef = useRef('');

  // Initialize Fathom when the app loads
  useEffect(() => {
    if (process.env.IS_PROD) {
      load(FATHOM_SITE_ID, {
        includedDomains: ['resist.bot'],
      });
    }
  }, []);

  // Posthog
  useEffect(() => {
    posthog.init(
      process.env.NEXT_PUBLIC_POSTHOG_KEY || '',
      posthogConfigPagesRouter
    );

    const handleRouteChange = () => posthog?.capture('$pageview');

    const handleRouteChangeStart = () =>
      posthog?.capture('$pageleave', {
        $current_url: oldUrlRef.current,
      });

    Router.events.on('routeChangeComplete', handleRouteChange);
    Router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, []);

  // Track user login changes
  useEffect(() => {
    if (user?.id) {
      posthog?.identify(user.id, {
        stripe_customer_id: user.stripe.customerId,
      });
    }
  }, [user]);

  // Sentry
  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user.id });
    } else {
      Sentry.setUser({ id: null });
    }
  }, [user]);

  return (
    <SWRConfig value={swrConfig}>
      <PostHogProvider client={posthog}>
        <Component {...pageProps} />
      </PostHogProvider>
    </SWRConfig>
  );
};

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.object,
};

export default App;
