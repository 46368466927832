import { type PostHogConfig } from 'posthog-js';

export const posthogConfigAppRouter: Partial<PostHogConfig> = {
  api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  capture_pageleave: true, // Enable pageleave capture
  loaded: (posthog) => {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true'
    )
      posthog.debug();
  },
};

export const posthogConfigPagesRouter: Partial<PostHogConfig> = {
  api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  loaded: (posthog) => {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true'
    )
      posthog.debug();
  },
};
